<template>
  <div class="access">
  <div class="access2">
    <h2>行き方</h2>

    <img class="parking" src="../assets/front_tireman.webp" alt="アクセス">
    <p class="access_p">ミスタータイヤマン富士山御殿場店 勝間田タイヤは山中湖と箱根を繋ぐ国道138号沿にあります。<br><br>
    東名高速道路 御殿場インターから9分、新東名高速道路 新御殿場インターから3分の位置にあります。<br>
    御殿場プレミアムアウトレットからはクルマで10分、山中湖からは20分程度で着きます。
    </p>
    <h2 class="heading">乗用車の入口</h2>
    <div class="inner">
    <img class="parking" src="../assets/carparking1.webp" alt="乗用車駐車場1">
    <img class="parking" src="../assets/carparking2.webp" alt="乗用車駐車場2">
    </div>
    <p class="access_p">
    乗用車の入口は箱根側にあります。<br>
    個人のお客様や普通車でお越しの方はこちらの駐車場をご利用ください。
    </p>

    <h2 class="heading">トラックの入口</h2>
    <div class="inner">
    <img class="parking" src="../assets/parking_big.webp" alt="アクセス">
    <img class="parking" src="../assets/bigtruckp.webp" alt="大型車両">
    <img class="parking" src="../assets/parking3.webp" alt="トラック作業場">
    </div>
    <p class="access_p">
    大型トラック・ダンプの広めの入口は富士山側にあります。<br>
    国道138号沿いで、どちらの方面からもスムーズに入れます。
    </p>

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3255.5320046656275!2d138.91672521539522!3d35.31760838028073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019771c2aa02be9%3A0xb5bb682c17c99296!2z44Of44K544K_44O844K_44Kk44Ok44Oe44OzIOWvjOWjq-WxseW-oeauv-WgtOW6lyDli53plpPnlLDjgr_jgqTjg6Q!5e0!3m2!1sja!2sjp!4v1663991824298!5m2!1sja!2sjp" width=100% height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p>静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/68HfK1CfSBGu4P1P9">Google Maps</a>
  </div>
  <div>
  <Footer/>
  </div>
  </div>

</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Footer
  }
}
</script>

<style scoped>
.access{
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}
.access2{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
}
.access_p{
  padding-bottom: 2rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.access h2{
  padding-top: 0.3rem;
  font-size: 1.5rem;
}

.about_p{
  padding-top: 7.3rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media screen and ( max-width:767px )
{
  .access img {
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
  .parking_full {
	display: block;
	width: 100%;
	height: auto;
  }
  .inner {
	margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
  }
}
@media screen and ( min-width:768px )
{
  .access img {
    width: 50%;
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-right: 2rem;
    margin:0 auto;
  }
  .parking_full {
	display: block;
	width: 100%;
	height: auto;
  }
  .inner {
  }
}
</style>
